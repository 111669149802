.seccon{
  display: flex;
  width: 100%;
  height: 100vh;
}
.boardCon{
  width: 45%;
  height: 100vh;
  background-color: black;
  color: white;
  display: flex;
  font-size: 43px;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
}.SignInCon{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 55%;
  background-color: antiquewhite;
  height: 100vh;
}.signsubcon{
  width: 40%;
  display: flex;
  flex-direction: column;
}.fang{
  display: flex;
  justify-content: space-around;
}.LowerConInLogin {
  min-height: 100vh;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: 'Roboto';
}
.formCon{
  background-color: white;
  padding: 13px;
  border-radius: 13px;
  margin: 13px;
  width: 100%;
  display: flex;
  flex-direction: column;

}
.inpu{
  border-radius: 13px;
  border-width: 0.5px;
  margin-bottom: 18px;
  margin-top: 12px;
  outline: none;
  padding: 10px;
}
.butto{
  background-color: black;
  color: white;
  border-radius: 13px;
  padding: 13px;
}